import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M32.6014 38.3636V58H29.0442V38.3636H32.6014ZM36.4534 58V38.3636H43.817C45.3256 38.3636 46.5912 38.6449 47.6139 39.2074C48.643 39.7699 49.4197 40.5433 49.9438 41.5277C50.4743 42.5057 50.7396 43.6179 50.7396 44.8643C50.7396 46.1236 50.4743 47.2422 49.9438 48.2202C49.4133 49.1982 48.6302 49.9684 47.5947 50.5309C46.5592 51.087 45.284 51.3651 43.7691 51.3651H38.8888V48.4407H43.2897C44.1718 48.4407 44.8941 48.2873 45.4566 47.9805C46.0191 47.6737 46.4346 47.2518 46.703 46.7148C46.9779 46.1779 47.1153 45.5611 47.1153 44.8643C47.1153 44.1676 46.9779 43.554 46.703 43.0234C46.4346 42.4929 46.0159 42.0806 45.447 41.7866C44.8845 41.4862 44.159 41.3359 43.2705 41.3359H40.0106V58H36.4534Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
